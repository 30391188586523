"use client"
import CloseSvg from 'src/components/svg/CloseSvg';
import theme from 'src/components/themes/DefaultTheme';
import getURLParameter from 'src/lib/client/getURLParameter';
import { useAdContext } from 'src/lib/contexts/AdContext';
import styled, { css } from 'src/lib/styles/css';
import { useEffect, useState } from 'react';


//#region [Props]
type AdProps = {
    targetId: string;

    supportsFixedAd?: boolean;
    canHide?: boolean;
    className?: string;
    removeIfNoAd?: boolean;
    pageHasStickyMenu?: boolean;
    noHeader?: boolean;
    height?: 300 | "300";
    mobileScreenWidth?: boolean;
};
//#endregion

//#region [Component]
export default function Ad({ targetId, className, supportsFixedAd = false, canHide = false, removeIfNoAd = false, pageHasStickyMenu = false, height, mobileScreenWidth = false, noHeader = false }: AdProps) {
    const [hasLoadedAd, setHasLoadedAd] = useState(!removeIfNoAd);
    const [hasNoAds, setHasNoAds] = useState(false);
    const [closeAd, setCloseAd] = useState(false);
    const adContext = useAdContext();

    useEffect(() => {
        // remove the ad if the user wants it gone by clicking the close button
        if (removeIfNoAd && closeAd && typeof window !== "undefined") {
            setTimeout(() => {
                setHasNoAds(true);
            }, 500);
        }
    }, [closeAd, removeIfNoAd]);

    useEffect(() => {
        if (adContext.loadedAds.includes(targetId)) {
            setHasLoadedAd(true);
        }
    }, [adContext.loadedAds, targetId]);

    useEffect(() => {
        // remove ads so we can test a page without it
        const skipAds = getURLParameter("skipAds");
        if (skipAds && skipAds === "true") {
            setHasNoAds(true);
            setHasLoadedAd(true);
        }
    }, []);

    if (removeIfNoAd && hasNoAds) {
        console.debug("Removing add ", removeIfNoAd, hasNoAds);
        return null;
    }

    const Wrapper = noHeader ? SMinimalAdWrapper : SAdWrapper;

    return <Wrapper $hide={canHide && !hasLoadedAd} $mobileScreenWidth={mobileScreenWidth} className={className}>
        {!noHeader && <SAdHeading className="adHeading">Annonse</SAdHeading>}
        <SAdContent
            $supportsFixedAd={supportsFixedAd}
            $isClosing={closeAd}
            //$isLoaded={hasLoadedAd}
            $height={height}
        >
            <div id={targetId} className="adnun"></div>
            {canHide && hasLoadedAd && <SAdCloser
                aria-label='Skjul annonse'
                title="Skjul annonse"
                onClick={() => setCloseAd(true)}><SCloseSvg /></SAdCloser>}
        </SAdContent>
    </Wrapper>;
}
//#endregion

//#region [Styles]
type SAdWrapperProps = {
    $hide?: boolean;
    $mobileScreenWidth: boolean;
};

const SMinimalAdWrapper = styled.div<SAdWrapperProps>`
	position: relative;
	max-width: 100vw;
    @supports(container-type: inline-size) {
	    container-type: inline-size;
	    container-name: adwrapper;
    }

	${props => props.$mobileScreenWidth ? css`
		margin-left: ${`-${theme.maxContentPadding}`};
		margin-right: ${`-${theme.maxContentPadding}`};
		width: 100vw;

		@media ${theme.mq.desktop} {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
		}
	` : ""}

	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		max-width: calc(100vw - var(--page-padding, 0) * 2);
	}

	@media (min-width: ${theme.maxContentWidth}px) {
		//@media (orientation: landscape) and (min-width: settings.$maxContentWidth) {
		//max-width: calc(${theme.maxContentWidth}px - (${theme.maxContentPadding} * 2));
		max-width: ${theme.maxContentWidth}px;
	}

`;

const SAdWrapper = styled(SMinimalAdWrapper)`
	&::after {
		content: "";
		height: 10px;
		background-color: var(--buttoncolor);

		width: 100%;
		display: block;
		max-width: 100vw;

		@media ${theme.mq.desktop} {
			//@media #{settings.$desktop} {//@media (orientation: landscape) {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}

	${props => props.$hide === true ? css`
		${SAdHeading} {
			display: none;
		}

		&::after {
			content: none;
		}
	`: ""}
`;

const SAdHeading = styled.div`

	background-color: var(--buttoncolor);

	height: 26px;
	text-align: center;
	font-size: 12px;
	line-height: 26px;
	vertical-align: middle;
	color: var(--buttontextcolor);
	max-width: 100vw;

	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

`;

type SAdContentProps = {
    $supportsFixedAd?: boolean;
    $isClosing?: boolean;
    $height?: string | number;
    //$isLoaded?: boolean;
}

const MOBILE_ASPECT = 200 / 320;
const DESKTOP_ASPECT = 300.0 / 2000.0;
const SPECIAL_FRONTPAGE_ASPECT = 380 / 802;

const SAdContent = styled.div<SAdContentProps>`
	${props => props.$supportsFixedAd === true ? css`
		position: relative;
		clip-path: inset(0 -100% 0 -100%);
	` : ""}

	${props => props.$height?.toString() === "300" ? css`
		overflow: hidden;
		height: calc(100vw * ${MOBILE_ASPECT});
		height: calc(100cqw * ${MOBILE_ASPECT});

		@media screen and (min-width: 768px) {
			height: calc((100vw - var(--page-padding, 0) * 2) * ${DESKTOP_ASPECT});
		}


		@media screen and (min-width: ${theme.maxContentWidth}px) {
			height: calc(100% * ${DESKTOP_ASPECT});
		}
        @supports(container-type: inline-size) {
            @container adwrapper (min-width: 768px) {
                height: calc(100cqw * ${DESKTOP_ASPECT});
            }
        }
	`: ""}
	/* Kommentert ut siden den enn så lenge ikker er i bruk
	${props => props.$height?.toString() === "375" ? css`
		overflow: hidden;
		height: calc(100vw * ${MOBILE_ASPECT});
		height: calc(100cqw * ${MOBILE_ASPECT});

		@media screen and (min-width: 768px) {
			height: calc((50vw - 17.5px - var(--page-padding, 0) * 2) * ${SPECIAL_FRONTPAGE_ASPECT});
		}

		@media screen and (min-width: ${theme.maxContentWidth}px) {
			height: calc(
				((${theme.maxContentWidth} - var(--page-padding, 0) * 2 - 35px) / 2) * ${SPECIAL_FRONTPAGE_ASPECT}
			);
		}
	`: ""}*/
`;

const SAdCloser = styled.button`
	position: absolute;
	background: none;
	width: 40px;
	height: 40px;
	border: none;
	top: 10px;
	right: 10px;
	padding: 0;
	z-index: 0;

	display: none;
`;

const SCloseSvg = styled(CloseSvg)`
	fill: var(--buttoncolor);
`;

//#endregion